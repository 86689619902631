var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        title: "修改密码",
        width: "450",
        draggable: "",
        "mask-closable": false,
        closable: false,
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "top",
            rules: _vm.formValidate,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "原密码", prop: "oldPass" } },
            [
              _c("Input", {
                attrs: {
                  type: "password",
                  password: "",
                  placeholder: "请输入现在使用的密码",
                },
                model: {
                  value: _vm.form.oldPass,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oldPass", $$v)
                  },
                  expression: "form.oldPass",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "新密码", prop: "newPass" } },
            [
              _c("SetPassword", {
                attrs: { placeholder: "请输入新密码，长度为6-20个字符" },
                on: { "on-change": _vm.changeInputPass },
                model: {
                  value: _vm.form.newPass,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "newPass", $$v)
                  },
                  expression: "form.newPass",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "确认新密码", prop: "rePass" } },
            [
              _c("Input", {
                attrs: {
                  type: "password",
                  password: "",
                  placeholder: "请再次输入新密码",
                },
                model: {
                  value: _vm.form.rePass,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "rePass", $$v)
                  },
                  expression: "form.rePass",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }